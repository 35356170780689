<template>
  <div class="main-content">
    
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>

</style>